let timeoutId, filePath, scriptID, winSize, beforeIsMobile, afterIsMobile;
const bp = 1024,
  slickScript = 'slick-script',
  slickScriptOption = 'slick-script-option',
  fullWidthSlider = 'fllwidthslider-script',
  fullWidthSliderOption = 'fllwidthslider-script-option';

function sliderScript() {
  var request = new XMLHttpRequest();
  request.open('GET', filePath, false);
  request.send(null);
  if (request.status === 200) {
    var script = document.createElement('script');
    script.text = request.responseText;
    script.id = scriptID;
    document.head.appendChild(script);
  }
}

!function verifyBeforeDevice() {
  winSize = window.innerWidth;
  winSize < bp ? beforeIsMobile = true : beforeIsMobile = false;
}();

!function sliderFilePath() {
  if (beforeIsMobile) {
    slickFilePath();
  } else {
    fullWidthSliderFilePAth();
  }
}();

function verifyAfterDevice() {
  winSize = window.innerWidth;
  winSize < bp ? afterIsMobile = true : afterIsMobile = false;
}

function slickFilePath () {
  filePath = 'src/js/slick.js';
  scriptID = slickScript;
  sliderScript();
}

function fullWidthSliderFilePAth () {
  filePath = 'src/js/jquery.fullwidthslider.1.0.js';
  scriptID = fullWidthSlider;
  sliderScript();
}

function removeFilePath () {
  let removeFullWidthSlider = document.getElementById(fullWidthSlider),
  removeSlick = document.getElementById(slickScript);
  if (removeFullWidthSlider) {
    removeFullWidthSlider.remove()
  }
  if (removeSlick) {
    removeSlick.remove()
  }
}

document.addEventListener('DOMContentLoaded', function () {
  window.addEventListener('resize', function () {
    clearTimeout( timeoutId )
    timeoutId = setTimeout(function () {
      verifyAfterDevice();
      if ((beforeIsMobile && afterIsMobile) || (!beforeIsMobile && !afterIsMobile)) {
        return;
      } else if (!beforeIsMobile && afterIsMobile) {
        removeFilePath();
        slickFilePath();
        location.reload();
      } else if (beforeIsMobile && !afterIsMobile) {
        removeFilePath();
        fullWidthSliderFilePAth();
        location.reload();
      }
    }, 100)
  })
})



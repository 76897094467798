$(window).on("load",function(){
    $(".checkPoint ul>li").heightLine();
    $(".profileBox>div").heightLine();
    //$("ul.staffList li p.positionTxt").heightLine();
    $("ul.staffList li").heightLine();
    $(".enqueteBox ul>li").heightLine();
    $(".itemSummery ul li>p").heightLine();
    $(".heightLine-roof").heightLine();
    $(".toiCaution li>span").heightLine();
});

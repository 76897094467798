// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
// import 'bootstrap'
// require("moment/locale/ja")
// require("tempusdominus-bootstrap-4")
// import '../stylesheets/application'
require("jquery")
require("conf.js")
require("heightLineSetting.js")
require("index.js")
require("jquery.autopager-1.0.0.js")
require("jquery.colorbox.js")
require("jquery.dotdotdot.min.js")
require("jquery.heightLine.js")
require("trix")
require("@rails/actiontext")

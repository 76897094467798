// キャッシュしない
(function() {
    $.ajaxSetup({ cache: false });
})();

$(document).ready(function(){
    //お問い合わせボタンを出現させるスクロールイベント
    $(window).scroll(function(){
        //最上部から現在位置までの距離を取得して、変数[now]に格納
        var now = $(window).scrollTop();

        //最下部から現在位置までの距離を計算して、変数[under]に格納
        var under = $('body').height() - (now + $(window).height());

        //最上部から現在位置までの距離(now)が200以上
        if(now > 200){
            //[#page-top]をゆっくりフェードインする
            $('.ftrScroll').fadeIn('slow');
        //それ以外だったらフェードアウトする
        }else{
            $('.ftrScroll').fadeOut('slow');
        }
    });


    // #で始まるアンカーをクリックした場合に処理
    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 400; // ミリ秒
        // アンカーの値取得
        var href= $(this).attr("href");
        // 移動先を取得
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を数値で取得
        var position = target.offset().top;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

    var ua = navigator.userAgent;
    if(ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 ){
        $('.tel-link').each(function(){
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
        });
    }

    /*dotdotdot*/
    $(".ddd , .newsBox h5 , .voiceList h3 , #blog #archiveList h3").dotdotdot({
        wrap:'letter'
    });
});


//Enterキーが押されても送信（submit）しない
function submitStop(e){
    if (!e) var e = window.event;

    if(e.keyCode == 13)
        return false;
}

//全てのinputのtext,password要素にそれを適応
window.onload = function (){
        var list = document.getElementsByTagName("input");
        for(var i=0; i<list.length; i++){
        if(list[i].type == 'text' || list[i].type == 'password'){
            list[i].onkeypress = function (event){
                return submitStop(event);
            };
        }
    }
}


var count = 0;

function switchMenu(){
    if(count % 2 == 0)
        $('header nav').stop().fadeIn(300);
    else
        $('header nav').stop().fadeOut(300);
    count++;
}
$(function () {
    $('#openBtn').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        switchMenu();
    });
    $('#closeBtn').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        switchMenu();
    });
});



$(function(){
 $(".showroomPict").each(function(){
  var set = $(this);
  var btn = set.find(".thumbnaillist li a");
  var image = set.find(".mainimglist img");
	// hover時
	$(btn).hover(function(){
		$(image).attr("src",$(this).attr("href")).fadeIn();
		$(image).attr("alt",$(this).attr("title"));
	});
	//クリック時
	$(".thumbnaillist a").click(function(){
	return false;
	});
});
});

$(function() {
    $(".informationTab li").click(function() {
        var num = $(".informationTab li").index(this);
        $(".tabContent").removeClass('active');
        $(".tabContent").eq(num).addClass('active');
        $(".informationTab li").removeClass('active');
        $(this).addClass('active')
    });
});